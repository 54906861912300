<template>
  <div :style="`width: 100%; ${viewHeight(54)}`">
    <toolbar back-button title="Incidents Map">
      <v-spacer></v-spacer>
      <v-btn icon color="primary" @click="toggleFilter = !toggleFilter">
        <v-icon v-if="!toggleFilter">mdi-filter-menu</v-icon>
        <v-icon v-if="toggleFilter">mdi-filter-off-outline</v-icon>
      </v-btn>
      <v-divider vertical class="mx-1" />
      <v-combobox
        class="ma-0"
        v-model="selectedTypes"
        :items="getIncidentTypes"
        item-id="id"
        item-text="name"
        label="Incident Types Filter"
        multiple
        outlined
        dense
      ></v-combobox>
      <!-- <v-btn-toggle
          v-model="selectedTypes"
          multiple
        >
        <v-btn color="primary" v-for="(t, i) in getIncidentTypes" :key="i" small class="pa-1">
          <strong>{{ t.name }}</strong>
        </v-btn>
      </v-btn-toggle> -->
    </toolbar>

    <incidents-filter
        v-model="filter"
        @clear="clearFilter"
        @filter="loadItems"
        v-show="toggleFilter"
      />

    <l-map
      ref="map"
      :style="`${viewHeight(toggleFilter ? 178 : 129, 0)}; widht: 100%; z-index: 0;`"
      :zoom="zoom"
      :center="center"
      @update:zoom="updateZoom"
      @update:center="updateCenter"
      @ready="setMap"
      @mousemove="updateCoordinates"
    >
      <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
      <l-geo-json :geojson="featureGeojson"></l-geo-json>
      <l-geo-json :geojson="geojson" :options="geojsonOptions" />

      <v-marker-cluster>
        <l-marker
          v-for="(marker, index) in markers"
          :key="index"
          :lat-lng="marker.location"
          @click="selectionChanged(marker)"
          @mouseenter="(e) => hoverIn(marker, e)"
          @mouseleave="(e) => hoverOut(marker, e)"
        >
          <!-- <l-icon>
            <div :id="`pin-${marker.id}`" :class="`marker ${selected && selected.id === marker.id ? 'marker-selected' : ''}`" />
          </l-icon> -->
          <l-icon
            :icon-url="getMarkerPin(marker.incidentTypeName)"
            :icon-size="[36, 40]"
            :icon-anchor="[18, 40]"
            />
              <!-- :class-name="selected && selected.id === marker.id ? 'marker-selected' : ''" -->
          <!-- <l-tooltip
            :options="{ direction: 'right' }"
            style="
              width: 220px;
              padding: 0;
              margin: 0;
              background: transparent;
              border: none;
            "
            v-if="!selected"
          >
            <incident-card :pined="false" :incident="marker" />
          </l-tooltip> -->
        </l-marker>
      </v-marker-cluster>

      <l-control position="topright" v-if="selected">
        <incident-card pined :incident="selected" @click:pin="goToSelected" />
      </l-control>

      <l-control position="bottomright" style="text-align: right;">
        <v-img width="300" contain class="shadow" src="/legend.png" v-if="toggleLegend" style="border-radius: 5px; margin-bottom: 4px;" />
        <v-btn x-small fab @click="toggleLegend = !toggleLegend" color="white" elevation="2">
          <v-icon>mdi-map-legend</v-icon>
        </v-btn>
      </l-control>

      <l-control position="bottomleft">
        <v-chip color="white" label x-small class="pa-1 shadow"
          >{{ hoverLat }} / {{ hoverLng }}</v-chip
        >
      </l-control>
    </l-map>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import oblast from "@/data/oblast.js";
import L from "leaflet";
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';
import {
  LMap,
  LTileLayer,
  LGeoJson,
  LMarker,
  LIcon,
  // LTooltip,
  LControl,
} from "vue2-leaflet";
import IncidentsFilter from "./IncidentsFilter";
import IncidentCard from "./IncidentCard";
import Toolbar from "@/common/components/Toolbar";

import * as esri from "esri-leaflet";


export default {
  name: "IncidentsMap",
  components: {
    LMap,
    LTileLayer,
    LGeoJson,
    LMarker,
    LIcon,
    // LTooltip,
    LControl,
    IncidentCard,
    Toolbar,
    IncidentsFilter,
    'v-marker-cluster': Vue2LeafletMarkerCluster,
  },
  data() {
    return {
      toggleLegend: false,
      toggleFilter: false,
      map: null,
      loading: false,
      zoom: 6,
      center: [48.71, 33.179],
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      featureLayerUrl:
        "https://services6.arcgis.com/k7zpUv2q45NlJJXV/arcgis/rest/services/Ukraine_Front_Line_NEW/FeatureServer",
      attribution: "OSM",
      featureGeojson: null,
      geojson: oblast,
      geojsonOptions: {
        color: "#009edb",
        weight: 0.5,
        opacity: 0.65,
      },
      hoverLat: 0,
      hoverLng: 0,
      selectedTypes: [],
      filter: {
        search: null,
      },
      filterReset: {
        search: null,
      },

    };
  },
  mounted() {
    this.loadTypes(true);
    this.loadItems();
  },
  computed: {
    ...mapGetters("incidents", [
      "getIncidents",
      "getIncidentsTotal",
      "getSelectedIncident",
    ]),
    ...mapGetters("areas", ["getAdmin1Areas"]),
    ...mapGetters("types", ["getTypesByGroup"]),

    selected() {
      return this.getSelectedIncident;
    },
    selection() {
      return !this.getSelectedIncident ? [] : [this.getSelectedIncident];
    },
    getTotal() {
      const total = this.getIncidentsTotal;
      return total ? total.toLocaleString("en") : 0;
    },
    markers() {
      const items = this.notEmptyArray(this.getIncidentTypeIds)
        ? this.getIncidents.filter((f) =>
            this.getIncidentTypeIds.includes(f.incidentType)
          )
        : this.getIncidents;

      return items.filter((f) => f.latitude && f.longitude).map((m) => ({
        ...m,
        location: this.getPoint(m),
      }));
    },

    getIncidentTypes() {
      return this.getTypesByGroup('SRS_INCIDENT_TYPE');
    },
    getIncidentTypeIds() {
      // const it = this.getIncidentTypes;
      let ids = [];
      for (let t of this.selectedTypes) {
        // const index = this.selectedTypes[i];
        // ids.push(it[index].id);
        ids.push(t.id);
      }
      return ids;
    },
  },
  methods: {
    ...mapActions("incidents", [
      "loadIncidentsByParams",
      "loadIncidents",
      "setSelectedIncident",
      "clearSelectedIncident",
    ]),
    ...mapActions("areas", ["loadAdmin1Areas"]),
    ...mapActions("types", ["loadTypes"]),

    loadFeatureLayer() {
      this.$nextTick(() => {
        // esri.basemapLayer('Topographic').addTo(this.map);

        var frontline = esri
          .featureLayer({
            url: "https://services6.arcgis.com/k7zpUv2q45NlJJXV/ArcGIS/rest/services/Ukraine_Front_Line_NEW/FeatureServer/12",
            apiKey: "AAPK18128e50bf0c452ebdc6c2565b4502c1TJdlcIvsFA6dqiUqt5zSW_0suzoj36Y8c1PD53-3930DiAaJSAholVIJUneGSig8",
            style: function () {
              return {
                color: "#FF0000", 
                weight: 3,
                opacity: 1
              };
            }
          });
        frontline.bindTooltip(this.$t("si.frontline"));
        frontline.addTo(this.map);
      });
    },

    getMarkerPin(type) {
      const path = `/pin_${type ? type.replace(" ", "_").toLowerCase() : "unknown"}.png`
      return path
    },

    async setMap() {
      await this.$nextTick();
      this.map = this.$refs.map.mapObject;

      setTimeout(() => {
        this.goToSelected();
        this.loadFeatureLayer();
        // this.map.invalidateSize();
      }, 500);
    },

    goToSelected() {
      if(this.selected) {
        this.map.setView(this.getPoint(this.selected), this.zoom);
      }
    },

    updateCoordinates(event) {
      this.hoverLat = event.latlng.lat.toFixed(6);
      this.hoverLng = event.latlng.lng.toFixed(6);
    },

    getPoint(incident) {
      return L.latLng(incident.latitude, incident.longitude);
    },
    updateZoom(newZoom) {
      this.zoom = newZoom;
    },
    updateCenter(newCenter) {
      this.center = newCenter;
    },

    loadItems() {
      this.loading = true;
      let params = {
        ...this.filter,
      };

      this.loadIncidentsByParams(params)
        .then(() => {
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },

    selectionChanged(item) {
      if ((item && !this.selected) || (item && this.selected && item.id !== this.selected.id)) {
        this.setSelectedIncident(item);
      } else {
        this.clearSelectedIncident();
      }
    },
    hoverIn(marker) {
      if(marker) {
        document.getElementById(`pin-${marker.id}`).classList.add("marker-hover");
      }
    },
    hoverOut(marker) {
      if(marker) {
        document.getElementById(`pin-${marker.id}`).classList.remove("marker-hover");
      }
    },
    clearFilter() {
      this.filter = { ...this.filterReset };
    },

  },
};
</script>

<style>
.leaflet-tooltip {
  padding: 0px !important;
  border: none !important;
}

.marker {
  background: url('/pin.png');
  margin-left: -12px;
  margin-top: -35.5px;
  width: 36px !important;
  height: 42px !important
}

.marker-selected::before {
  content: "";
  border-radius: 15px;
  border: dashed 3px #ffffff;
  position: absolute;
  width: 46px !important;
  height: 50px !important;
  margin-left: -5px;
  margin-top: -4px;
}

.marker-hover:not(.marker-selected)::after {
  content: "";
  border-radius: 35px;
  border: dashed 2px #ffffff;
  position: absolute;
  width: 50px !important;
  height: 50px !important;
  margin-left: -7px;
  margin-top: -4px;
	/* -webkit-animation: marker-hover 0.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.2s infinite reverse both;
          animation: marker-hover 0.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.2s infinite reverse both; */
  -webkit-animation: rotate-center 1.7s linear 0.2s infinite ;
          animation: rotate-center 1.7s linear 0.2s infinite ;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-12-29 5:15:42
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation marker-hover
 * ----------------------------------------
 */
 @-webkit-keyframes marker-hover {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes marker-hover {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2023-12-29 5:45:30
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-center
 * ----------------------------------------
 */
@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.v-text-field__details {
  display: none;
}

</style>
```