<template>
  <v-card :flat="!pined" width="330" v-if="incident" style="margin: 0px">
    <v-toolbar color="primary" dark flat dense short>
      <v-toolbar-title class="headline">{{
        incident.oblastName
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn small icon @click="clearSelectedIncident" v-if="pined">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-list dense class="pa-0">
      <v-list-item
        style="
          font-size: 10pt;
          padding: 4px;
          max-height: 60px;
          line-height: 15px;
          overflow-y: auto;
          margin: 0px 0 3px 7px;
          display: block;
        "
      >
        {{ incident.narrativeWereReported }}
      </v-list-item>
      <v-divider />
      <v-list-item style="font-size: 10pt; padding: 0">
        <v-list-item-content
          class="d-flex align-baseline py-0"
          style="max-height: 170px; overflow-y: auto"
        >
          <table class="props-list">
            <tr class="grey lighten-3">
              <td>Coordinates</td>
              <td style="text-align: right">
                {{ incident.latitude }} / {{ incident.longitude }}
              </td>
            </tr>
            <tr>
              <td>{{ $t("si.incidentType") }}:</td>
              <td style="text-align: right">{{ incident.incidentTypeName }}</td>
            </tr>
            <tr class="grey lighten-3">
              <td>{{ $t("si.area") }}:</td>
              <td style="text-align: right">{{ incident.areaName }}</td>
            </tr>
            <tr>
              <td>{{ $t("si.oblast") }}:</td>
              <td style="text-align: right">{{ incident.oblastName }}</td>
            </tr>
            <tr class="grey lighten-3">
              <td>{{ $t("si.raion") }}:</td>
              <td style="text-align: right">{{ incident.raionName }}</td>
            </tr>
            <tr>
              <td>{{ $t("si.hromada") }}:</td>
              <td style="text-align: right">{{ incident.hromadaName }}</td>
            </tr>
            <tr class="grey lighten-3">
              <td>{{ $t("si.settlement") }}:</td>
              <td style="text-align: right">{{ incident.settlementName }}</td>
            </tr>
            <tr>
              <td colspan="2">
                <incident-stats :incident="incident" />
              </td>
            </tr>
          </table>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider />
    <v-card-actions v-if="pined">
      <v-btn
        small
        color="primary"
        icon
        @click="
          $router.push({
            name: 'incident',
            params: { incidentId: incident.id },
          })
        "
        ><v-icon>mdi-pencil</v-icon></v-btn
      >
      <v-btn small color="primary" icon @click="$emit('click:pin')"
        ><v-icon>mdi-target</v-icon></v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import IncidentStats from "../components/IncidentStats";

export default {
  name: "IncidentCard",
  components: { IncidentStats },
  props: {
    incident: Object,
    pined: Boolean,
  },
  methods: {
    ...mapActions("incidents", ["clearSelectedIncident"]),
  },
};
</script>

<style scoped>
.props-list > tr > td {
  font-size: 8pt;
  padding: 2px 5px !important;
}
</style>