<template>
  <div>
    <v-btn-toggle v-model="activeTab" class="stats-cats mb-1 mt-2">
      <v-btn
        :color="activeTab === 0 ? 'primary' : ''"
        depressed
        x-small
        :value="0"
        @click="activeTab = 0"
        :style="`width: 50px; ${activeTab === 0 ? 'color: #fff;' : ''}`"
        >{{ $t("si.typeOfArms") }}</v-btn
      >
      <v-btn
        :color="activeTab === 1 ? 'primary' : ''"
        depressed
        x-small
        :value="1"
        @click="activeTab = 1"
        :style="`width: 112px; ${activeTab === 1 ? 'color: #fff;' : ''}`"
        >{{ $t("si.civilianInfrastructure") }}</v-btn
      >
      <v-btn
        :color="activeTab === 2 ? 'primary' : ''"
        depressed
        x-small
        :value="2"
        @click="activeTab = 2"
        :style="`width: 65px; ${activeTab === 2 ? 'color: #fff;' : ''}`"
        >{{ $t("si.military") }}</v-btn
      >
      <v-btn
        :color="activeTab === 3 ? 'primary' : ''"
        depressed
        x-small
        :value="3"
        @click="activeTab = 3"
        :style="`width: 75px; ${activeTab === 3 ? 'color: #fff;' : ''}`"
        >{{ $t("si.casualties") }}</v-btn
      >
    </v-btn-toggle>

    <v-card flat class="mt-1 pa-1" style="border: 1px solid #eee;">
      <apexchart
        v-if="incident && activeTab === 0"
        type="bar"
        height="230"
        :options="getChartOptions(typeOfArmsCategories)"
        :series="getSeries(typeOfArmsValues)"
      />

      <apexchart
        v-if="incident && activeTab === 1"
        type="bar"
        height="250"
        :options="getChartOptions(infraCategories)"
        :series="getSeries(infraValues)"
      />

      <apexchart
        v-if="incident && activeTab === 2"
        type="bar"
        height="50"
        :options="getChartOptions(militaryCategories)"
        :series="getSeries(militaryValues)"
      />

      <apexchart
        v-if="incident && activeTab === 3"
        type="bar"
        height="80"
        :options="getChartOptions(casualtiesCategories)"
        :series="getSeries(casualtiesValues)"
      />
    </v-card>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    incident: Object,
  },
  data() {
    return {
      activeTab: 0,
    };
  },
  computed: {
    typeOfArmsCategories() {
      return [
        this.$t("si.weaponModel"),
        this.$t("si.artilleryUnidentified"),
        this.$t("si.mlrs"),
        this.$t("si.tank"),
        this.$t("si.mortar"),
        this.$t("si.missiles"),
        this.$t("si.airStrike"),
        this.$t("si.interceptedByAirDefence"),
        this.$t("si.clusterMunitions"),
        this.$t("si.explosions"),
        this.$t("si.heavyMachineGun"),
        this.$t("si.smallArms"),
        this.$t("si.uav"),
        this.$t("si.erwUxo"),
        this.$t("si.iedLandmine"),
        this.$t("si.chemical"),
        this.$t("si.nuclear"),
        this.$t("srs.other"),
      ];
    },
    typeOfArmsValues() {
      return [
        this.incident.typeOfArmsWeaponModel,
        this.incident.typeOfArmsArtilleryUnidentified,
        this.incident.typeOfArmsMLRS,
        this.incident.typeOfArmsTank,
        this.incident.typeOfArmsMortar,
        this.incident.typeOfArmsMissiles,
        this.incident.typeOfArmsAirStrike,
        this.incident.typeOfArmsInterceptedByAirDefence,
        this.incident.typeOfArmsClusterMunitions,
        this.incident.typeOfArmsExplosions,
        this.incident.typeOfArmsHeavyMachineGun,
        this.incident.typeOfArmsSmallArms,
        this.incident.typeOfArmsUAV,
        this.incident.typeOfArmsERWUXO,
        this.incident.typeOfArmsIEDLandmine,
        this.incident.typeOfArmsChemical,
        this.incident.typeOfArmsNuclear,
        this.incident.typeOfArmsOtherValue,
      ];
    },
    infraCategories() {
      return [
        this.$t("si.privateHomes"),
        this.$t("si.multistoreResidentialBuilding"),
        this.$t("si.medicalFacility"),
        this.$t("si.educationalFacility"),
        this.$t("si.unspecifiedCivInfrastructure"),
        this.$t("si.industrialPremises"),
        this.$t("si.privateCompany"),
        this.$t("si.agriculturalObjects"),
        this.$t("si.railroad"),
        this.$t("si.bridges"),
        this.$t("si.waterSupply"),
        this.$t("si.nuclearPowerPlant"),
        this.$t("si.electricPlantStation"),
        this.$t("si.powerLines"),
        this.$t("si.dams"),
        this.$t("si.gasLines"),
        this.$t("srs.other"),
      ];
    },
    infraValues() {
      return [
        this.incident.infraPrivateHomes,
        this.incident.infraMultistoreResidentialBuilding,
        this.incident.infraMedicalFacility,
        this.incident.infraEducationalFacility,
        this.incident.infraUnspecifiedCivInfrastructure,
        this.incident.infraIndustrialPremises,
        this.incident.infraPrivateCompany,
        this.incident.infraAgriculturalObjects,
        this.incident.infraRailroad,
        this.incident.infraBridges,
        this.incident.infraWaterSupply,
        this.incident.infraNuclearPowerPlant,
        this.incident.infraElectricPlantStation,
        this.incident.infraPowerLines,
        this.incident.infraDams,
        this.incident.infraGasLines,
        this.incident.infraOtherValue,
      ];
    },
    militaryCategories() {
      return [
        this.$t("si.ammunitionDepot"),
        this.$t("si.militaryBase"),
        this.$t("srs.other"),
      ];
    },
    militaryValues() {
      return [
        this.incident.militaryAmmunitionDepot,
        this.incident.militaryMilitaryBase,
        this.incident.militaryOtherValue,
      ];
    },
    casualtiesCategories() {
      return [
        this.$t("si.civilianFatalities"),
        this.$t("si.civilianInjuries"),
        this.$t("si.polSESUFatalities"),
        this.$t("si.polSESUInjured"),
        this.$t("srs.other"),
      ];
    },
    casualtiesValues() {
      return [
        this.incident.peopleCivilianFatalities,
        this.incident.peopleCivilianInjuries,
        this.incident.peoplePolSESUFatalities,
        this.incident.peoplePolSESUInjured,
        this.incident.peopleOtherValue,
      ];
    },
  },
  methods: {
    getSeries(values) {
      return [
        {
          data: values,
        },
      ];
    },
    getChartOptions(categories) {
      return {
        colors: ["#009edb"],
        chart: {
          parentHeightOffset: 0,
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "85%",
            padding: { left: 10 },
          },
        },
        dataLabels: {
          offsetY: 2,
          style: {
            fontSize: "8pt",
            fontWeight: "400",
          },
        },
        xaxis: {
          categories,
          axisBorder: { show: false },
          axisTicks: { show: false },
          crosshairs: { show: false },
          labels: {
            show: false,
          },
        },
        yaxis: {
          showForNullSeries: false,
          labels: {
            align: "left",
            offsetY: 3,
            offsetX: -2,
            minWidth: 80,
            maxWidth: 80,
            style: {
              fontSize: "8pt",
            },
          },
        },
        grid: {
          show: false,
          padding: { left: 0, right: 0, top: -30, bottom: 0 },
        },
        tooltip: {
          enabled: false,
        },
        legend: {
          show: false,
        },
      };
    },
  },
};
</script>

<style>
.stats-cats .v-btn {
  height: 40px !important;
  text-wrap: wrap !important;
}
.stats-cats .v-btn .v-btn__content {
  display: contents !important;
}
</style>